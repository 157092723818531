@font-face {
  font-family: Averta;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/Averta_Extrabold.woff2') format('woff2'),
    url('../fonts/Averta_Extrabold.woff') format('woff');
}

@font-face {
  font-family: Averta;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Averta_Regular.woff2') format('woff2'),
    url('../fonts/Averta_Regular.woff') format('woff');
}

@font-face {
  font-family: My First Font;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/My_First_Font.woff2') format('woff2'),
    url('../fonts/My_First_Font.woff') format('woff');
}

@font-face {
  font-family: Neue Haas Unica Pro;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Neue_Haas_Unica_Pro_Regular.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Unica_Pro_Regular.woff') format('woff');
}

@font-face {
  font-family: Neue Haas Unica Pro;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Neue_Haas_Unica_Pro_Bold.woff2') format('woff2'),
    url('../fonts/Neue_Haas_Unica_Pro_Bold.woff') format('woff');
}

@font-face {
  font-family: Core Sans;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Core_Sans_Regular.woff2') format('woff2'),
    url('../fonts/Core_Sans_Regular.woff') format('woff');
}

@font-face {
  font-family: Core Sans;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Core_Sans_Bold.woff2') format('woff2'),
    url('../fonts/Core_Sans_Bold.woff') format('woff');
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Lato_Regular.woff2') format('woff2'),
    url('../fonts/Lato_Regular.woff') format('woff');
}

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/Lato_Extrabold.woff2') format('woff2'),
    url('../fonts/Lato_Extrabold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1MmgVxFIzIXKMnyrYk.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local(Roboto-Regular),
    url(//fonts.gstatic.com/s/roboto/v15/5YB-ifwqHP20Yn46l_BDhA.eot?#) format('eot'),
    url(//fonts.gstatic.com/s/roboto/v15/CWB0XYA8bzo0kSThX0UTuA.woff2) format('woff2'),
    url(//fonts.gstatic.com/s/roboto/v15/2UX7WLTfW3W8TclTUvlFyQ.woff) format('woff');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local(Roboto-Medium),
    url(//fonts.gstatic.com/s/roboto/v15/RxZJdnzeo3R5zSexge8UUfY6323mHUZFJMgTvxaG2iE.eot?#)
      format('eot'),
    url(//fonts.gstatic.com/s/roboto/v15/RxZJdnzeo3R5zSexge8UUVtXRa8TVwTICgirnJhmVJw.woff2)
      format('woff2'),
    url(//fonts.gstatic.com/s/roboto/v15/RxZJdnzeo3R5zSexge8UUT8E0i7KZn-EPnyo3HZu7kw.woff)
      format('woff');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2)
    format('woff2');
}
