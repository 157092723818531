@import './variables.css';

html {
  box-sizing: border-box;
  font-size: 62.5%;
  height: 100%;
}

body {
  background: #fff;
  color: var(--color-tertiary);
  font-size: 1.4em;
  font-weight: 400;
  font-family: system-ui;
  height: 100%;
}

#root {
  height: 100%;
}

img {
  vertical-align: bottom;
}

.old-image {
  border-radius: 2px;
}

*,
*::after,
*::before {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}
