@import '../../vendor/styles/variables.css';

.toast {
  &__success {
    min-width: 93.33%;
    background-color: var(--color-primary-lighter);
    color: var(--color-primary);
    padding: 9px 6px;
    position: fixed;
    text-align: center;
    z-index: 1051;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transform: translate(-50%, -48%);
    transition: opacity 0.5s ease-in-out;
  }

  &--success-visible {
    opacity: 1 !important;
    transform: translate(-50%, -50%);
  }

  &--error-visible {
    opacity: 1 !important;
    transform: translate(-50%, 150%);
  }

  &__error {
    min-width: 93.33%;
    background-color: #f8e0e0;
    color: #ff4d4d;
    padding: 9px 6px;
    position: fixed;
    z-index: 1051;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transform: translate(-50%, -48%);
    transition: opacity 0.5s ease-in-out;
  }
}
