/* stylelint-disable */
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?3lqzgk');
  src: url('../fonts/icomoon.eot?3lqzgk#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?3lqzgk') format('woff2'),
    url('../fonts/icomoon.ttf?3lqzgk') format('truetype'),
    url('../fonts/icomoon.woff?3lqzgk') format('woff'),
    url('../fonts/icomoon.svg?3lqzgk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-display: swap;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-coupon_code:before {
  content: '\e939';
  color: #727171;
}
.icon-best-service:before {
  content: '\e935';
  color: #4a4a4a;
}
.icon-breakfast:before {
  content: '\e936';
  color: #4a4a4a;
}
.icon-comfortable-room:before {
  content: '\e937';
  color: #4a4a4a;
}
.icon-room-amenities:before {
  content: '\e938';
  color: #4a4a4a;
}
.icon-hurry_icon .path1:before {
  content: '\e91f';
  color: rgb(255, 255, 255);
  opacity: 0.01;
}
.icon-hurry_icon .path2:before {
  content: '\e920';
  margin-left: -1em;
  color: rgb(255, 102, 115);
}
.icon-Price-drop_icon .path1:before {
  content: '\e930';
  color: rgb(255, 255, 255);
  opacity: 0.01;
}
.icon-Price-drop_icon .path2:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(59, 71, 151);
}
.icon-Price-drop_icon .path3:before {
  content: '\e932';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-user_icon .path1:before {
  content: '\e933';
  color: rgb(255, 164, 0);
}
.icon-user_icon .path2:before {
  content: '\e934';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.01;
}
.icon-redeem-rewards:before {
  content: '\e92e';
}
.icon-star-rewards:before {
  content: '\e940';
}
.icon-rewards-earn:before {
  content: '\e92f';
}
.icon-member-rates .path1:before {
  content: '\e928';
  color: rgb(68, 68, 68);
}
.icon-member-rates .path2:before {
  content: '\e929';
  margin-left: -1.0771484375em;
  color: rgb(255, 255, 255);
}
.icon-member-rates .path3:before {
  content: '\e92a';
  margin-left: -1.0771484375em;
  color: rgb(68, 68, 68);
}
.icon-member-rates .path4:before {
  content: '\e92b';
  margin-left: -1.0771484375em;
  color: rgb(255, 255, 255);
}
.icon-member-rates .path5:before {
  content: '\e92c';
  margin-left: -1.0771484375em;
  color: rgb(68, 68, 68);
}
.icon-member-rates .path6:before {
  content: '\e92d';
  margin-left: -1.0771484375em;
  color: rgb(255, 255, 255);
}
.icon-credit-expired:before {
  content: '\e921';
  color: #aeaeae;
}
.icon-credit .path1:before {
  content: '\e922';
  color: rgb(68, 68, 68);
}
.icon-credit .path2:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(13, 180, 80);
}
.icon-credit .path3:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(13, 180, 80);
}
.icon-debit .path1:before {
  content: '\e925';
  color: rgb(68, 68, 68);
}
.icon-debit .path2:before {
  content: '\e926';
  margin-left: -1em;
  color: rgb(255, 102, 115);
}
.icon-debit .path3:before {
  content: '\e927';
  margin-left: -1em;
  color: rgb(255, 102, 115);
}
.icon-entertainment:before {
  content: '\e903';
}
.icon-gift:before {
  content: '\e91d';
}
.icon-lightning:before {
  content: '\e919';
}
.icon-amenity-toiletries:before {
  content: '\e900';
}
.icon-amenity-wifi:before {
  content: '\e901';
}
.icon-amenity-tv:before {
  content: '\e902';
}
.icon-amenity-breakfast:before {
  content: '\e91e';
}
.icon-amenity-ac:before {
  content: '\e904';
}
.icon-google-plus:before {
  content: '\f0d5';
}
.icon-facebook:before {
  content: '\e91b';
  color: #3b5998;
}
.icon-clock:before {
  content: '\e8b5';
}
.icon-circle-plus:before {
  content: '\e905';
}
.icon-arrow-left:before {
  content: '\e906';
}
.icon-phone:before {
  content: '\e907';
}
.icon-checkbox:before {
  content: '\e908';
}
.icon-checkbox-outline:before {
  content: '\e909';
}
.icon-radiobutton-checked:before {
  content: '\e90a';
}
.icon-close:before {
  content: '\e90b';
}
.icon-edit:before {
  content: '\e90c';
}
.icon-calendar:before {
  content: '\e91a';
}
.icon-directions:before {
  content: '\e90d';
}
.icon-email:before {
  content: '\e159';
  color: #000;
}
.icon-current-location:before {
  content: '\e90e';
}
.icon-help:before {
  content: '\e887';
}
.icon-angle-down:before {
  content: '\e90f';
}
.icon-angle-left:before {
  content: '\e910';
}
.icon-angle-right:before {
  content: '\e911';
}
.icon-angle-up:before {
  content: '\e912';
}
.icon-offer:before {
  content: '\e913';
}
.icon-radiobutton-outline:before {
  content: '\e914';
}
.icon-radiobutton:before {
  content: '\e915';
}
.icon-circle-minus:before {
  content: '\e916';
}
.icon-visibility-on:before {
  content: '\e8f4';
}
.icon-search:before {
  content: '\e917';
}
.icon-share:before {
  content: '\e918';
}
.icon-visibility-off:before {
  content: '\e8f5';
}
