@import '../../vendor/styles/variables.css';

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &__text {
    color: var(--color-primary);
    margin-top: 16px;
  }

  &__circle {
    animation: spin 600ms infinite linear;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid var(--color-primary-light);
    border-right-color: var(--color-primary);
    display: inline-block;
  }

  &__btn-circle {
    animation: spin 600ms infinite linear;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid var(--color-quinary);
    border-right-color: var(--color-quaternary);
    display: inline-block;
  }
}

.loader-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1069;
  background: transparent;
  background-color: #fff;
  opacity: 1;
  &--transparent-bg-loader{
    background-color: #fff6;
  }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
