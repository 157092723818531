@import './variables.css';

.hide {
  display: none !important;
}

.text {
  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }

  &-capitalize {
    text-transform: capitalize;
  }

  &-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.floaty {
  width: 100%;
  min-height: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: fixed !important;
  box-shadow: var(--shadow-secondary);
  will-change: transform;
}

.angle-link {
  font-size: 1.4rem;
  color: var(--color-link) !important;

  &::after {
    font-family: "icomoon" !important;
    font-size: 1.6rem;
    content: "\e911";
    position: relative;
    top: 2px;
    right: 0;
  }

  @media (--medium-screen) {
    font-size: 1.6rem;

    &::after {
      font-size: 1.8rem;
      top: 3px;
    }
  }
}
