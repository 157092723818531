@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeOut;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 1%, 0);
  }

  to {
    opacity: 1;
    transform: inherit;
  }
}

.fadeInUp {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 1%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes slideInUpDelay {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUpDelay {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-delay: 0.3s;
  animation-name: slideInUpDelay;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-delay: 0.3s;
  animation-name: slideOutDown;
}
