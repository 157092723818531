@import '../../vendor/styles/variables.css';

a {
  display: block;
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ol {
  list-style: decimal inside;
  padding: 0;
  margin: 0;
}

p {
  margin: 0 0 8px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 4px;
}

.heading-1 {
  font-size: 2.1rem;
  color: var(--color-secondary);

  &--preview {
    opacity: 0.1;
    height: 24px;
    width: 32px;
    background: var(--color-secondary);
  }

  @media (--medium-screen) {
    font-size: 2.4rem;

    &--preview {
      height: 27px;
    }
  }
}

.heading-2 {
  font-size: 1.8rem;
  color: var(--color-secondary);

  &--preview {
    opacity: 0.1;
    height: 20px;
    width: 32px;
    background: var(--color-secondary);
  }

  @media (--medium-screen) {
    font-size: 2.1rem;

    &--preview {
      height: 24px;
    }
  }
}

.heading-3 {
  font-size: 1.6rem;
  color: var(--color-tertiary);

  &--preview {
    opacity: 0.1;
    height: 18px;
    width: 32px;
    background: var(--color-tertiary);
  }

  @media (--medium-screen) {
    font-size: 1.9rem;

    &--preview {
      height: 21px;
    }
  }
}

.subheading-1 {
  font-size: 1.4rem;
  color: var(--color-secondary);

  &--preview {
    opacity: 0.1;
    height: 16px;
    width: 32px;
    background: var(--color-secondary);
  }

  @media (--medium-screen) {
    font-size: 1.6rem;

    &--preview {
      height: 18px;
    }
  }
}

.subheading-2 {
  font-size: 1.2rem;
  color: var(--color-secondary);

  &--preview {
    opacity: 0.1;
    height: 13px;
    width: 32px;
    background: var(--color-secondary);
  }

  @media (--medium-screen) {
    font-size: 1.4rem;

    &--preview {
      height: 16px;
    }
  }
}

.subheading-3 {
  font-size: 1.2rem;
  color: var(--color-tertiary);

  &--preview {
    opacity: 0.1;
    height: 13px;
    width: 32px;
    background: var(--color-tertiary);
  }

  @media (--medium-screen) {
    font-size: 1.4rem;

    &--preview {
      height: 16px;
    }
  }
}

.text-1 {
  font-size: 1.2rem;
  color: var(--color-secondary);

  &--preview {
    opacity: 0.1;
    height: 13px;
    width: 32px;
    background: var(--color-secondary);
  }

  @media (--medium-screen) {
    font-size: 1.4rem;

    &--preview {
      height: 16px;
    }
  }
}

.text-2 {
  font-size: 1.2rem;
  color: var(--color-tertiary);

  &--preview {
    opacity: 0.1;
    height: 13px;
    width: 32px;
    background: var(--color-tertiary);
  }

  @media (--medium-screen) {
    font-size: 1.4rem;

    &--preview {
      height: 16px;
    }
  }
}

.text--tertiary {
  color: var(--color-tertiary);
}

.text--primary {
  color: var(--color-primary);
}

.text--error {
  color: var(--color-error);
}

.text--link {
  color: var(--color-link);
}
